import { Injectable, ComponentFactoryResolver, Injector, Inject, TemplateRef, Type } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ModalComponent } from './modal.component';
import { Subscription } from 'rxjs';

@Injectable({ providedIn: 'root' }) // TODO/Max: Do we need services injected this way?
export class ModalService {
    private closeSubscription: Subscription;

    constructor (
        private componentFactoryResolver: ComponentFactoryResolver,
        private injector: Injector,
        @Inject(DOCUMENT) private document: Document
        ) {}

        public show<T>(title: string, content: any) {
          // used for creating text content
          const factory = this.componentFactoryResolver.resolveComponentFactory(ModalComponent);
          const componentRef = factory.create(this.injector, []);

          componentRef.instance.title = title;
          componentRef.instance.content = content;
          componentRef.hostView.detectChanges();

          const modalElement = this.document.body.appendChild(componentRef.location.nativeElement);

          // if(content.htmlBody) {
          //   modalElement.querySelector('.dynamic-content').innerHTML = content.htmlBody;
          // }

          this.closeSubscription = componentRef.instance.close.subscribe( () => {
            modalElement.remove();
            componentRef.destroy();
            this.closeSubscription.unsubscribe();
          });
        }

    public showComponent<T>(title: string, content: any, component: Type<any>) {
      // used for creating text content
      // const element = this.document.createTextNode(body);
      // const componentRef = factory.create(this.injector, [ [element] ]);

      // used for creating dynamic component content
      const contentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
      const contentComponentRef = contentFactory.create(this.injector);

      contentComponentRef.instance.content = content;
      contentComponentRef.hostView.detectChanges();

      const factory = this.componentFactoryResolver.resolveComponentFactory(ModalComponent);
      const componentRef = factory.create(this.injector, [contentComponentRef.location.nativeElement]);

      componentRef.instance.title = title;
      componentRef.hostView.detectChanges();

      const modalElement = this.document.body.appendChild(componentRef.location.nativeElement);
      modalElement.querySelector('.dynamic-content').appendChild(contentComponentRef.location.nativeElement);

      this.closeSubscription = componentRef.instance.close.subscribe( () => {
        modalElement.remove();
        contentComponentRef.destroy();
        componentRef.destroy();
        this.closeSubscription.unsubscribe();
      });
    }
}
