import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
    selector: 'image-view',
    templateUrl: './image-view.component.html',
    styleUrls: ['./image-view.component.scss']
})
export class ImageViewComponent {
    @Input() src: string;
    @Output() close: EventEmitter<void> = new EventEmitter<void>();

    onClose() {
        this.close.emit();
    }
}
