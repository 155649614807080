<div class="content" role="main">
  <div class="menu">
    <div class="ig-polflam-logo">
      <img src="../../assets/imgs/logo_polflam_pl.png" alt="polflam logo">
    </div>
    <div class="choice_lang">
      <img src="../../assets/imgs/flaga_pl.png" alt="polish language">
      <img src="../../assets/imgs/flaga_en.png" alt="english language">
    </div>
  </div>
  <app-prezenter></app-prezenter>
</div>
