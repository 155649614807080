import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PrezenterComponent } from './prezenter/prezenter.component';
import { ModalComponent } from './modal/modal.component';
import { ImageViewComponent } from './image-view/image-view.component';
import { HotspotPageComponent } from './prezenter/hotspot-page/hotspot-page.component';

@NgModule({
  declarations: [
    AppComponent,
    PrezenterComponent,
    ModalComponent,
    ImageViewComponent,
    HotspotPageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
