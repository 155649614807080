<div class="backdrop" (click)="onClose()"></div>

<div class="modal" tabindex="-1" role="dialog" style="display: block;">
  <div class="modal-dialog" role="document">
    <div class="modal-content container">
      <div class="modal-header">
        <span>{{title}}</span>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="onClose()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body container">
        <div class="left-column box">
          <div class="dynamic-content">
            <img src="{{this.content.realisations[this.realisationIndex].imgSrc}}" alt="Realisation image">
            <div [innerHTML]="this.content.realisations[this.realisationIndex].htmlBody"></div>
          </div>
        </div>
        <div class="right-column">
          <div class="decription box">
            <span class="box-title">opis</span>
            <!-- <div class="image-contain">
              <button (click)="setRealisationIndex(0)">
                <img src="../../assets/svg//home.svg" alt="Home icon">
              </button>
            </div> -->
          </div>
          <div class="realisations box">
            <span class="box-title">realizacje</span>
            <div class="images-container">
              <span *ngFor="let image of getGallery(); let index = index">
                <img [src]="image" alt="Gallery image" (click)="setRealisationIndex(index)">
              </span>
            </div>
          </div>
          <div class="cards box">
            <span class="box-title">karty produktu</span>
            <div class="references-container">
              <ul>
                <li *ngFor="let reference of getReferences()">
                  <img [src]="getRefIcon(reference.type)" alt="Refference icon">
                  {{ reference.description }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
