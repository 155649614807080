import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class ApiService {
  constructor(private http: HttpClient) {}

  public getHotspotPage(id: String) {
    // return this.http.get(`/api/hotspots/${id}`);

    const url = "./../../assets/data/wnetrze.json";
    return this.http.get(url).pipe(
      map((res) => {
        console.log(res);
        return res["hotspots"][0];
      })
    );
  }
}
