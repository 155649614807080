import { ApiService } from './api.service';
import { ModalService } from './../modal/modal.service';
import { Component, OnInit } from '@angular/core';

declare var jQuery: any;
declare var WR360: any;

@Component({
  selector: 'app-prezenter',
  templateUrl: './prezenter.component.html',
  styleUrls: ['./prezenter.component.scss']
})
export class PrezenterComponent implements OnInit {

  imageData = data;
  galleryId = 'myLightbox';

  constructor(
    private modalService: ModalService,
    private apiService: ApiService,

  ) { }

  ngOnInit(): void {
    this.initWebrotator();
  }

  private initWebrotator() {

    jQuery(document).ready(() => {
      const rotator = WR360.ImageRotator.Create('wr360PlayerId');

      rotator.licenseFileURL = 'license.lic';
      rotator.settings.configFileURL = '../../assets/360_assets/Prezenter/Prezenter.xml';
      rotator.settings.graphicsPath = '../../assets/img/zoom_light';
      rotator.settings.googleEventTracking = false;
      rotator.settings.responsiveBaseWidth = 1250;
      rotator.settings.responsiveMinHeight = 700;

      rotator.settings.apiReadyCallback = (api, isFullScreen) => {
        if(!isFullScreen) {
          this.callBackOnAction(api);
        }
      };
      rotator.runImageRotator();
    });
  }

  private callBackOnAction(api: any): void {
    api.hotspots.onAction((hotspotConfig) => {
      const id = hotspotConfig.id;
      this.openModal(id)
      return false;
    });
  }

  openModal(id: string) {
    // TODO: Add some loading on asynchronous call
    this.apiService.getHotspotPage(id).subscribe( (page: any) => {
      this.modalService.show(page.title, page.content);
    })
  }

  private initGallery() {
    /** Basic Gallery Example */

    // Creat gallery items
    // this.items = this.imageData.map(item => new ImageItem({ src: item.srcUrl, thumb: item.previewUrl }));


    // /** Lightbox Example */

    // // Get a lightbox gallery ref
    // const lightboxRef = this.gallery.ref('lightbox');

    // // Add custom gallery config to the lightbox (optional)
    // lightboxRef.setConfig({
    //   imageSize: ImageSize.Cover,
    //   thumbPosition: ThumbnailsPosition.Top
    // });

    // // Load items into the lightbox gallery ref
    // lightboxRef.load(this.items);
  }
}
const data = [
  {
    srcUrl: 'https://preview.ibb.co/jrsA6R/img12.jpg',
    previewUrl: 'https://preview.ibb.co/jrsA6R/img12.jpg'
  },
  {
    srcUrl: 'https://preview.ibb.co/kPE1D6/clouds.jpg',
    previewUrl: 'https://preview.ibb.co/kPE1D6/clouds.jpg'
  },
  {
    srcUrl: 'https://preview.ibb.co/mwsA6R/img7.jpg',
    previewUrl: 'https://preview.ibb.co/mwsA6R/img7.jpg'
  },
  {
    srcUrl: 'https://preview.ibb.co/kZGsLm/img8.jpg',
    previewUrl: 'https://preview.ibb.co/kZGsLm/img8.jpg'
  }
];


