<div>
  <section class="modal_content_left">

  </section>

  <section class="modal_content_right">
    <h2>Dokumenty:</h2>
    <ul class="ig-list-doc">
      <li *ngFor="let ref of content.references"><a href="{{ref.href}}">{{ ref.description }}</a></li>
    </ul>

    <h2>Realizacje</h2>

    <div class="galery-container">
      <span *ngFor="let image of content.gallery">
        <img src="{{image.src}}" alt="">
      </span>
    </div>
  </section>
</div>
