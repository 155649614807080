import { Component, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss']
})
export class ModalComponent {
    @Input() title: string;
    @Input() content;
    @Output() close: EventEmitter<void> = new EventEmitter<void>();

    public realisationIndex: number = 0;

    constructor(private cd: ChangeDetectorRef) {}

    getGallery() {
      return this.content.realisations.map(realisation => realisation.imgSrc);
    }

    setRealisationIndex(index) {
      this.realisationIndex = index;
      this.cd.detectChanges();
    }

    getReferences() {
      return this.content.references;
    }
    getRefIcon(type) {
      return `../../assets/svg/${type}.svg`;
    }

    onClose() {
        this.close.emit();
    }
}
