import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'hotspot-page',
  templateUrl: './hotspot-page.component.html',
  styleUrls: ['./hotspot-page.component.scss']
})
export class HotspotPageComponent implements OnInit {
  @Input() content;

  constructor() {}
  ngOnInit() {}
}
